<template>
  <div class="statistic-oblige ">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date début</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            required
            :value="date_debut"
            @input="handleDateTimePicker($event, 'date_debut')"
            :disabled-date="disabledStartDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter custom-date-picker-filter-white-back"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Date fin</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            :value="date_fin"
            @input="handleDateTimePicker($event, 'date_fin')"
            :clear-button="true"
            :disabled-date="disabledEndDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter custom-date-picker-filter-white-back"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2 mb-2 mt-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Organsime</span>
          </div>
          <multiselect
            v-model="organisme"
            :options="getListOrganismeObligee"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner organisme"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getLoadingDataFilterObligeeThProjects"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div
          v-if="getLoaderStatisticOblige"
          class="chargement chargement-loading-icon mt-1 ml-3"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="box-end-position">
          <!-- <SearchInput
            :value="search"
            @changeSearchValue="changeSearchValue"
            className="search-white-color mr-1"
          /> -->
          <b-button
            @click="handleClickOpneAllBlock"
            size="sm"
            class="button-export-style  ml-2 mr-1 button-white-with-icon-color"
            :title="
              OpenAllBlock ? 'Fermé tous les blocs' : 'Ouvrir tous les blocs'
            "
          >
            <img height="16px" width="15px" src="@/assets/open-close.jpeg" />
          </b-button>
          <export-rapport-oblige-statistic
            :filter="computedFilter"
            :genereRapport="exportRapportFactureObligee"
          />
          <!-- <b-button
            size="sm"
            class="button-export-style  ml-1 mr-2 button-white-with-icon-color"
            title="Filter"
            id="popover-filter-obligee"
          >
            <font-awesome-icon icon="filter" />
          </b-button> -->
          <b-popover
            triggers="focus"
            target="popover-filter-obligee"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <div class="box-label-champ mr-2 mb-2 mt-2">
              <div class="label-box-style w-126-px">
                <span class="title-tabel">Organsime</span>
              </div>
              <multiselect
                v-model="organisme"
                :options="getListOrganismeObligee"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner organisme"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>

            <div class="box-label-champ mr-2 mb-2 mt-2">
              <div class="label-box-style w-126-px">
                <span class="title-tabel">Filiale Installateur</span>
              </div>
              <multiselect
                v-model="filiale"
                :options="getListInstallateurbligee"
                :multiple="true"
                label="name"
                track-by="id"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner filiale"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>
            <div class="box-label-champ mr-2 mt-2">
              <div class="label-box-style w-126-px">
                <span class="title-tabel">Lot</span>
              </div>
              <multiselect
                v-model="lot"
                :options="getListLotsOligee"
                :multiple="true"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner lot"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :loading="getLoadingDataFilterObligeeThProjects"
                @input="handleFilter"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>
            <div class="box-label-champ mr-2 mt-2">
              <div class="label-box-style w-126-px">
                <span class="title-tabel">Statut</span>
              </div>
              <multiselect
                v-model="statue"
                :options="optionsSelectStatut"
                :multiple="true"
                class="customSelectMultipleFilter"
                :showLabels="false"
                placeholder="sélectionner statut"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                @input="handleFilter"
                label="full_name"
                track-by="value"
              >
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length"
                    >{{ values.length }} statut</span
                  ></template
                >
              </multiselect>
            </div>
          </b-popover>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div
        class="block-left-filter"
        :class="{
          'block-left-filter-disabled': !showBlockFilter
        }"
      >
        <div class="block-title">
          <div class="d-flex" v-if="showBlockFilter">
            <div class="title">
              Liste master filiale
            </div>
            <font-awesome-icon
              v-if="computedMasterList.length"
              :icon="master_id.length ? 'times' : 'plus'"
              class="time-style-statistic-oblige ml-1 mt-1"
              @click="
                master_id.length
                  ? (master_id = [])
                  : (master_id = [...computedMasterList]);
                handleFilter();
              "
              :title="master_id.length ? 'Vider la liste' : 'Sélectionne tout'"
            />
          </div>
          <img
            height="18px"
            width="18px"
            src="@/assets/Icon ionic-md-arrow-dropright-circle.png"
            class="flesh-icon"
            :class="{ 'flesh-icon-rigth': showBlockFilter == false }"
            @click="showBlockFilter = !showBlockFilter"
          />
        </div>
        <div class="Search-style-component" v-show="showBlockFilter">
          <div class="search-box">
            <font-awesome-icon icon="search" class="icon" />
            <input
              class="search-input"
              placeholder="Chercher"
              v-model="searchMaster"
            />
          </div>
        </div>
        <div class="mt-2" v-show="showBlockFilter">
          <div
            v-if="loderMaster"
            class="d-flex align-items-center justify-content-center"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
          <ul class="ul-societe-style" v-else>
            <li
              v-for="(item, index) in computedMasterList"
              :key="index"
              class="li-societe-style"
              :class="{
                'li-societe-style-active': activeItem(item) === true
              }"
              @click="clickItemList(item)"
            >
              {{ item.name }}
              <font-awesome-icon
                v-if="activeItem(item) === true"
                icon="check-circle"
                class="check-icon-style"
              />
            </li>
          </ul>
        </div>
      </div>

      <div
        class="block-rigth-content "
        :style="showBlockFilter ? '' : 'width:97%'"
      >
        <!-- recap master  -->
        <div class="master-item-block recap-item-block ml-2">
          <div class="recap-title">
            <span class="text">
              REPARTITION DES FILIALES SUR LES MASTERS
            </span>
            <font-awesome-icon
              @click="openRacapBlock = !openRacapBlock"
              icon="caret-down"
              v-if="!openRacapBlock"
            />
            <font-awesome-icon
              @click="openRacapBlock = !openRacapBlock"
              icon="caret-up"
              v-else
            />
          </div>
          <div v-if="openRacapBlock" class="chart-content">
            <div
              v-if="getLoaderStatisticOblige"
              class="d-flex align-items-center justify-content-center"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
            <div
              v-if="
                getDataStatisticOblige && getDataStatisticOblige.masters_logos
              "
              class="d-flex"
            >
              <div
                v-for="(logo, key) in getDataStatisticOblige.masters_logos"
                :key="'logo' + key"
              >
                <img
                  :src="logo"
                  style="max-height:20px;max-width:100%;display:inline-block;margin:15px;"
                />
              </div>
            </div>
            <mdbBarChart
              v-if="
                getDataStatisticOblige &&
                  getDataStatisticOblige.nombreFilialesMasters
              "
              class="chart"
              ref="chartRef"
              type="bar"
              :data="doughnutChartDataRcap"
              :options="doughnutChartOptionsRacap"
              :height="150"
            ></mdbBarChart>
            <div class="text-center" v-else-if="!getLoaderStatisticOblige">
              Il n'y a aucun donné à afficher
            </div>
          </div>
        </div>
        <!-- data master filiale  -->
        <div class="master-item-block recap-item-block ml-2">
          <div class="recap-title">
            <span class="text">
              DEPOTS DES MASTER FILIALES
            </span>
            <div class="d-flex">
              <img
                class="mr-2 mt-2"
                height="11px"
                width="13px"
                src="@/assets/close-image-without-back.png"
                :title="
                  masterBlochVisibility
                    ? 'Fermé tous les blocs de master filiale'
                    : 'Ouvrir tous les blocs de master filiale'
                "
                @click="hendleChangeVisibilityGlobalMasterBlok"
                v-if="
                  getDataStatisticOblige &&
                    getDataStatisticOblige.filiales_volumes
                "
              />
              <font-awesome-icon
                @click="openMasterBlock = !openMasterBlock"
                icon="caret-down"
                v-if="!openMasterBlock"
              />
              <font-awesome-icon
                @click="openMasterBlock = !openMasterBlock"
                icon="caret-up"
                v-else
              />
            </div>
          </div>
          <div
            v-if="getLoaderStatisticOblige"
            class="d-flex align-items-center justify-content-center"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
          <div
            class="m-3"
            v-if="
              getDataStatisticOblige &&
                getDataStatisticOblige.array_masters &&
                openMasterBlock
            "
          >
            <div class="d-flex justify-content-end">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="groupBy"
                  :options="[
                    { text: 'Semaine', value: 'week' },
                    { text: 'Mois', value: 'month' }
                  ]"
                  :aria-describedby="ariaDescribedby"
                  name="plain-inline"
                  plain
                  @change="handleFilter"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <!-- <b-form-checkbox
              v-model="groupBy"
              @change="handleFilter"
              name="check-button"
              switch
              class="check-th"
              :value="'month'"
              :unchecked-value="'week'"
            >
              <b class="mt-1 switch-label-style">Par Mois </b>
            </b-form-checkbox> -->
            <div class="text-center mb-2">
              RECAP DES MASTERS FILIALES
            </div>
            <b-table-simple
              hover
              small
              caption-top
              responsive
              bordered
              class="table-statistic-oblige"
            >
              <b-thead head-variant="light">
                <b-tr v-if="groupBy == 'week'">
                  <b-th class="table-conter-style">Mois</b-th>
                <b-th
                    class=" table-conter-style text-center"
                    v-for="(f, indexE) in computedTableHeader(
                      Object.values(getDataStatisticOblige.array_masters)[0]
                    )"
                    :key="'th' + indexE"
                    :colspan="f.occ"
                    >{{ f.item }}</b-th
                  >
                  <b-th></b-th>
                </b-tr>
                <b-tr>
                  <b-th class="table-conter-style">Master filiale</b-th>
                  <b-th
                    class="table-conter-style"
                    v-for="(week_value, week_id) in Object.values(
                      getDataStatisticOblige.array_masters
                    )[0]"
                    :key="'th-install' + week_id"
                    >{{ week_id }}</b-th
                  >
                  <b-th>Total</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(filiale, key, indexI) in computedTableData(
                    getDataStatisticOblige.array_masters
                  )"
                  :key="'tr' + indexI"
                >
                  <b-td variant="active"> {{ key }}</b-td>
                  <b-td
                    class="text-right"
                    v-for="(week_value, week_id, indCol) in filiale"
                    :key="'td-install' + week_id"
                    :id="'td-install' + week_id"
                  >
                    {{
                      filiale[
                        Object.keys(
                          Object.values(getDataStatisticOblige.array_masters)[0]
                        )[indCol]
                      ]
                    }}
                  </b-td>
                  <b-td class="text-right table-conter-style">{{
                    computedTotalRow(filiale)
                  }}</b-td>
                </b-tr>

                <b-tr>
                  <b-td variant="active" class="table-conter-style">Total</b-td>
                  <b-td
                    v-for="(week_value, week_id) in Object.values(
                      getDataStatisticOblige.array_masters
                    )[0]"
                    :key="'td-total' + week_id"
                    class="text-right table-conter-style"
                    >{{
                      computedTotalCol(
                        getDataStatisticOblige.array_masters,
                        week_id
                      )
                    }}</b-td
                  >
                  <b-td class="table-conter-style text-right">{{
                    computedTotalGlobale(getDataStatisticOblige.array_masters)
                  }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div
            v-if="
              openMasterBlock &&
                getDataStatisticOblige &&
                getDataStatisticOblige.filiales_volumes
            "
            class="chart-content"
          >
            <div
              class="master-item-block ml-2"
              v-for="(item,
              key,
              index) in getDataStatisticOblige.filiales_volumes"
              :key="index"
            >
              <div
                class="chart-title"
                :style="
                  'border-color:' + getDataStatisticOblige.masters_colors[key]
                "
              >
                <img
                  :src="getDataStatisticOblige.masters_logos[key]"
                  width="100px"
                  height="25px"
                />
                <span class="text">
                  VOLUME EN GIGA POUR LE MASTER FILIALE
                  <b class="ml-2">
                    <span
                      :style="
                        'color:' + getDataStatisticOblige.masters_colors[key]
                      "
                      >{{ key }}</span
                    ></b
                  >
                </span>
                <font-awesome-icon
                  @click="handleClickVisibilityMasterBlock(index)"
                  icon="caret-down"
                  v-show="
                    masterBlochVisibility[index] &&
                      masterBlochVisibility[index].value
                  "
                />
                <font-awesome-icon
                  @click="handleClickVisibilityMasterBlock(index)"
                  icon="caret-up"
                  v-show="
                    masterBlochVisibility[index] &&
                      masterBlochVisibility[index].value == false
                  "
                />
              </div>
              <div
                class="chart-content"
                v-show="computedMasterBlochVisibility(index)"
              >
                <div class="font-weight-bold">Volume en GIGA par FILIALE</div>
                <div class="d-flex">
                  <div class="mr-2">
                    {{ groupBy == 'week' ? 'Semaine' : 'Mois' }}
                  </div>

                  <div class="block-week">
                    <div
                      v-for="(weekElement, indexW) in computedWeekList(item)"
                      :key="'master' + index + 'week' + indexW"
                      class="d-flex align-items-center mr-2"
                    >
                      <span
                        class="circle-week-color"
                        :style="
                          weekElement.color
                            ? 'background:' + weekElement.color
                            : ''
                        "
                      ></span>
                      <span>{{ weekElement.week }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex justify-content-start div-first-chart mb-4 mt-4"
                >
                  {{ doughnutChartData.backgroundColor }}
                  <div v-for="(filiale, indexF) in item" :key="'f' + indexF">
                    <mdbBarChart
                      class="chart"
                      ref="chartRef"
                      type="bar"
                      :data="doughnutChartData(filiale, key)"
                      :options="doughnutChartOptions(indexF)"
                      :height="170"
                    ></mdbBarChart>
                  </div>
                </div>
                <div
                  class="border-top-filiale mb-4 mt-4 pt-4"
                  :style="
                    'border-color:' + getDataStatisticOblige.masters_colors[key]
                  "
                >
                  <mdbBarChart
                    class="chart"
                    ref="chartRef"
                    type="bar"
                    :data="
                      doughnutChartDataInsta(
                        getDataStatisticOblige.masters_filiales_volumes[key]
                      )
                    "
                    :options="doughnutChartOptionsInsta"
                    :height="150"
                  ></mdbBarChart>
                </div>

                <div>
                  <b-table-simple
                    hover
                    small
                    caption-top
                    responsive
                    bordered
                    class="table-statistic-oblige"
                  >
                    <b-thead head-variant="light">
                      <b-tr v-if="groupBy == 'week'">
                        <b-th class="table-conter-style">Mois</b-th>
                        <b-th
                          class=" table-conter-style text-center"
                          v-for="(f, indexE) in computedTableHeader(
                            Object.values(item)[0]
                          )"
                          :key="'th' + indexE"
                          :colspan="f.occ"
                          >{{ f.item }}</b-th
                        >
                        <b-th></b-th>
                      </b-tr>

                      <b-tr
                        ><b-th class="table-conter-style"
                          >Installateur RGE</b-th
                        >
                        <b-th
                          class="table-conter-style"
                          v-for="(week_value, week_id) in Object.values(
                            item
                          )[0]"
                          :key="'th-install' + week_id"
                          >{{ week_id }}</b-th
                        >
                        <b-th>Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        v-for="(filiale, key, indexI) in computedTableData(
                          item
                        )"
                        :key="'tr' + indexI"
                      >
                        <b-td variant="active"> {{ key }}</b-td>
                        <b-td
                          class="text-right"
                          v-for="(week_value, week_id, indCol) in filiale"
                          :key="'td-install' + week_id"
                          :id="'td-install' + week_id"
                        >
                          {{
                            filiale[Object.keys(Object.values(item)[0])[indCol]]
                          }}
                        </b-td>
                        <b-td class="table-conter-style text-right">{{
                          computedTotalRow(filiale)
                        }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="active" class="table-conter-style"
                          >Total</b-td
                        >
                        <b-td
                          v-for="(week_value, week_id) in Object.values(
                            item
                          )[0]"
                          :key="'td-total' + week_id"
                          class="text-right table-conter-style"
                          >{{ computedTotalCol(item, week_id) }}</b-td
                        >
                        <b-td class="table-conter-style text-right">{{
                          computedTotalGlobale(item)
                        }}</b-td>
                      </b-tr>
                    </b-tbody></b-table-simple
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="!getLoaderStatisticOblige && openMasterBlock"
            class="text-center cfs-10"
          >
            Il n'y a aucun donné à afficher
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import VueApexCharts from 'vue-apexcharts';
import { mdbDoughnutChart, mdbBarChart } from 'mdbvue';

export default {
  data() {
    return {
      masterBlochVisibility: [],
      openMasterBlock: true,
      openRacapBlock: true,
      OpenAllBlock: true,
      OpenAllBlockMaster: true,
      showBlockFilter: true,
      searchMaster: null,
      loderMaster: false,
      date_debut: '2022-11-01',
      date_fin: null,
      search: null,
      organisme: [],
      filiale: [],
      lot: [],
      statue: [],
      groupBy: 'week',
      optionsSelectStatut: [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
        //  {
        //           value: 'Créé',
        //           full_name: 'Créé',
        //         },
        // { value: 'Annulée', full_name: 'Annulée' }
      ],

      doughnutChartOptionsInsta: {
        responsive: true,
        //style de nom de serie
        legend: {
          display: false,
          position: 'bottom'
        },
        plugins: {
          labels: {
            render: 'percentage',
            showActualPercentages: true
          }
        },
        //title de chart
        title: {
          display: true,
          text: 'Volume en GIGA par FILIALE',
          fontFamily: 'quicksand-medium',
          fontSize: 12,
          fontColor: 'black',
          position: 'top'
        },
        //le style de axes x et y
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: 10
                // callback: function(value) {
                //   const val = `${value}`;
                //   return val.length > 7 ? `${[...val.split(' ')]}...` : val;
                // }
              }
            }
          ],
          yAxes: [
            // {
            //   display: true,
            //   ticks: {
            //     fontSize: 10
            //   }
            // }
          ]
        },
        //l'ecriture dans les barres
        datalabels: {
          anchor: 'cneter',
          align: 'end',
          font: {
            size: 10
          }
        },
        type: 'bar',
        animation: {
          duration: 1,
          onComplete: function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = '#858585';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      },
      doughnutChartOptionsRacap: {
        responsive: true,
        //style de nom de serie
        legend: {
          display: false,
          position: 'bottom'
        },
        plugins: {
          labels: {
            render: 'value',
            // showActualPercentages: true
            display: true
          }
        },
        //title de chart
        title: {
          display: true,
          text: 'Nombre de Installateur RGE par Master Filiale',
          fontFamily: 'quicksand-medium',
          fontSize: 12,
          fontColor: 'black',
          position: 'top'
        },
        //le style de axes x et y
        scales: {
          xAxes: [
            {
              display: true,
              ticks: {
                fontSize: 10
                // callback: function(value) {
                //   const val = `${value}`;
                //   return val.length > 7 ? `${[...val.split(' ')]}...` : val;
                // }
              }
            }
          ],
          yAxes: [
            // {
            //   display: true,
            //   ticks: {
            //     fontSize: 10
            //   }
            // }
          ]
        },
        //label de bar
        datalabels: {
          anchor: 'cneter',
          align: 'end',
          font: {
            size: 10
          }
        },
        type: 'bar',
        //l'ecriture dans les barres
        animation: {
          duration: 1,
          onComplete: function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = '#858585';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      },
      weekList: {},
      master_id: []
    };
  },
  methods: {
    ...mapActions([
      'getAllDataForFilterObligee',
      'fetchDataStatisticOblige',
      'getSettingFilialeTh',
      'exportRapportFactureObligee'
    ]),
    redureC(array1) {
      const initialValue = 0;
      const sumWithInitial = array1.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue
      );
      return sumWithInitial;
    },
    hendleChangeVisibilityGlobalMasterBlok() {
      this.OpenAllBlockMaster = !this.OpenAllBlockMaster;
      this.masterBlochVisibility.map(i => (i.value = this.OpenAllBlockMaster));
    },
    handleClickVisibilityMasterBlock(index) {
      this.masterBlochVisibility[index].value = !this.masterBlochVisibility[
        index
      ].value;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    rendomColor() {
      // var colorList = Array.from(
      //   { length: 51 },
      //   () => '#' + Math.floor(Math.random() * 16777215).toString(16)
      // );
      var colorList = [];
      this.weekList = {};
      let lgth =
        this.groupBy == 'week'
          ? this.getDataStatisticOblige.weeks_list.length
          : this.getDataStatisticOblige.months_list.length;
      for (let i = 0; i < lgth; i++) {
        var rand = this.getRandomColor();
        while (colorList.includes(rand)) {
          rand = this.getRandomColor();
        }
        colorList.push(rand);
      }
      if (this.groupBy == 'week') {
        Object.values(this.getDataStatisticOblige.weeks_list).forEach(
          (key, index) =>
            (this.weekList[this.groupBy == 'week' ? key.split('-')[0] : key] =
              colorList[index])
        );
      } else {
        Object.values(this.getDataStatisticOblige.months_list).forEach(
          (key, index) =>
            (this.weekList[this.groupBy == 'week' ? key.split('-')[0] : key] =
              colorList[index])
        );
      }
    },
    handleClickOpneAllBlock() {
      this.OpenAllBlock = !this.OpenAllBlock;
      this.openMasterBlock = this.OpenAllBlock;
      this.openRacapBlock = this.OpenAllBlock;
    },
    clickItemList(item) {
      const index = this.master_id.findIndex(element => element.id === item.id);
      if (index !== -1) {
        this.master_id.splice(index, 1);
      } else {
        this.master_id.push(item);
      }
      this.handleFilter();
    },
    async handleFilter() {
      let params = {
        statistiques: 'oui',
        groupBy: this.groupBy
      };
      if (this.date_debut) {
        params.date_debut = this.date_debut;
      }
      if (this.date_fin) {
        params.date_fin = this.date_fin;
      }
      if (this.lot.length) {
        params.lot = this.lot;
      }
      if (this.organisme.length) {
        params.organisme = this.organisme.map(item => item.id);
      }
      if (this.filiale.length) {
        params.filiale = this.filiale.map(item => item.id);
      }
      if (this.search) {
        params.search = this.search;
      }
      if (this.master_id.length) {
        params.master_id = this.master_id.map(item => item.id);
      }
      if (this.statue.length) {
        params.statue = this.statue.map(item => item.value);
      }
      await this.fetchDataStatisticOblige(params);
    },
    changeSearchValue(value) {
      this.search = value;
      this.handleFilter();
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterObligee({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    async fetchMasterList() {
      this.loderMaster = true;
      this.getSettingFilialeTh({ type: 'master filiale' });
      this.loderMaster = false;
    }
  },
  watch: {
    getDataStatisticOblige() {
      this.rendomColor();
      this.masterBlochVisibility = [];
      if (this.getDataStatisticOblige) {
        let array_to_use = Object.keys(
          this.getDataStatisticOblige.filiales_volumes
        );

        array_to_use.forEach(element => {
          this.masterBlochVisibility.push({ value: false });
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingDataFilterObligeeThProjects',
      'getListOrganismeObligee',
      'getListInstallateurbligee',
      'getListLotsOligee',
      'getDataStatisticOblige',
      'getLoaderStatisticOblige',
      'getErrorStatisticOblige',
      'getSettingFilialesTh',
      'isComptabilite',
      'isControleGestion',
      'isSuperAdminGetter'
    ]),
    computedTableHeader() {
      return function(item) {
        let tab = Object.keys(item).map(
          i => i.split('/')[i.split('/').length - 1]
        );
        let Unique = Array.from(new Set(tab));

        return Unique.map(i => {
          return { item: i, occ: tab.filter(x => i == x).length };
        });
      };
    },
    computedTableData() {
      return function(item) {
        const sorted = Object.entries(item)
          .sort(
            ([, a], [, b]) =>
              this.redureC(Object.values(a)) - this.redureC(Object.values(b))
          )
          .reverse()
          .reduce(
            (r, [k, v]) => ({
              ...r,
              [k]: v
            }),
            {}
          );
        return sorted;
      };
    },
    computedMasterBlochVisibility() {
      return function(index) {
        if (index >= 0) {
          return (
            this.masterBlochVisibility &&
            this.masterBlochVisibility[index] &&
            this.masterBlochVisibility[index].value
          );
        }
      };
    },
    computedTotalRow() {
      return function(data) {
        if (data) {
          return Object.values(data).reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
        }
        return '-';
      };
    },
    computedTotalCol() {
      return function(data, key) {
        if (data) {
          return Object.values(data)
            .map(item => item[key])
            .reduce((accumulator, currentValue) => accumulator + currentValue);
        }
        return '-';
      };
    },
    computedTotalGlobale() {
      return function(data) {
        if (data) {
          return Object.values(data)
            .map(item => Object.values(item))
            .flat()
            .reduce((accumulator, currentValue) => accumulator + currentValue);
        }
        return '-';
      };
    },
    computedWeekList() {
      return function(data) {
        return [
          ...new Set(
            Object.values(data)
              .map(i => {
                return Object.keys(i);
              })
              .flat()
          )
        ].map(i => {
          return {
            week: this.groupBy == 'week' ? i.split('-')[0] : i,
            color: this.weekList[this.groupBy == 'week' ? i.split('-')[0] : i]
          };
        });
      };
    },
    doughnutChartOptions() {
      return function(key) {
        return {
          responsive: false,
          legend: {
            display: false,
            position: 'bottom'
          },

          title: {
            display: true,
            text: key,
            fontFamily: 'quicksand-medium',
            fontSize: 9,
            fontColor: '#747d8c',
            position: 'top'
          },
          plugins: {
            datalabels: {
              color: 'blue',
              labels: {
                title: {
                  font: {
                    weight: 'bold'
                  }
                },
                value: {
                  color: 'green'
                }
              }
            },
            labels: {
              render: 'value',
              // showActualPercentages: true
              display: true
            }
          },
          scales: {
            xAxes: [
              {
                display: true,
                ticks: {
                  fontSize: 8,
                  callback:
                    this.groupBy == 'week'
                      ? function(value) {
                          const val = `${value}`;
                          return val.split('-')[0];
                        }
                      : function(value) {
                          const val = `${value}`;
                          return val;
                        }
                }
              }
            ],
            yAxes: [{}]
          },
          datalabels: {
            anchor: 'cneter',
            align: 'end',
            font: {
              size: 10
            }
          },
          type: 'bar',
          animation: {
            duration: 1,
            onComplete: function() {
              var chartInstance = this.chart,
                ctx = chartInstance.ctx;
              ctx.textAlign = 'center';
              ctx.fillStyle = '#858585';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function(dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function(bar, index) {
                  var data = dataset.data[index];
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
              });
            }
          }
        };
      };
    },
    computedFilter() {
      return {
        lot: this.lot,
        organisme: this.organisme.map(item => item.id),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filiale.map(item => item.id),
        search: this.search,
        master_id: this.master_id.map(item => item.id),
        statue: this.statue.map(item => item.value)
      };
    },
    getSettingFilialesThComputed() {
      return this.isSuperAdminGetter ||
        this.isComptabilite ||
        this.isControleGestion
        ? [{ name: 'SANS MASTER', id: 'null' }, ...this.getSettingFilialesTh]
        : this.getSettingFilialesTh;
    },
    computedMasterList() {
      if (this.searchMaster != null) {
        return this.getSettingFilialesThComputed.filter(item =>
          item.name.toLowerCase().includes(this.searchMaster.toLowerCase())
        );
      }
      return this.getSettingFilialesThComputed;
    },
    doughnutChartData() {
      return function(data, key) {
        return {
          labels: Object.keys(data),
          datasets: [
            {
              label: key,
              data: Object.values(data),
              backgroundColor: Object.keys(data).map(i => {
                let color = this.groupBy == 'week' ? i.split('-')[0] : i;
                return this.weekList[color];
              })

              // hoverBackgroundColor: this.datasets.hoverBackgroundColor
            }
          ]
        };
      };
    },
    doughnutChartDataInsta() {
      return function(data) {
        return {
          labels: Object.keys(data).map(item => {
            return item.length > 7 ? item.split(' ') : item;
          }),
          datasets: [
            {
              label: data.name,
              data: Object.values(data),
              backgroundColor: '#118dff'
              // hoverBackgroundColor: this.datasets.hoverBackgroundColor
            }
          ]
        };
      };
    },
    doughnutChartDataRcap() {
      return {
        labels: Object.keys(
          this.getDataStatisticOblige.nombreFilialesMasters
        ).map(item => {
          return item.length > 7 ? item.split(' ') : item;
        }),
        datasets: [
          {
            label: '',
            data: Object.values(
              this.getDataStatisticOblige.nombreFilialesMasters
            ),
            backgroundColor: '#eb895f'
          }
        ]
      };
    },
    activeItem() {
      return function(item) {
        const index = this.master_id.findIndex(
          element => element.id === item.id
        );
        if (index === -1) {
          return false;
        } else {
          return true;
        }
      };
    }
  },
  components: {
    // SearchInput: () => import('@/views/component/SearchInput.vue'),
    exportRapportObligeStatistic: () =>
      import('./exportRapportObligeStatistic.vue'),
    mdbBarChart
  },
  mounted() {
    // this.rendomColor();
    this.date_debut = moment()
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD');
    this.getAllDataForFilterObligee({
      date_debut: this.date_debut,
      date_fin: this.date_fin
    });
    this.fetchMasterList();
    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.statistic-oblige {
  font-family: 'Montserrat', sans-serif;
  // display: flex;
  padding: 0px 15px 0px 30px;
  height: 95%;
  padding-top: 10px;
  //   width: 100%;
  .box-all-filter {
    background: transparent;
  }
  .block-left-filter-disabled {
    width: 27px !important;
    padding: 0px !important;
    padding-top: 6px !important;
  }
  .block-left-filter {
    width: 15%;
    background: #fff;
    border-radius: 13px;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    .block-title {
      padding-left: 6px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 9px;
        font-weight: 600;
        color: #868484;
      }
    }
    .Search-style-component {
      .search-box {
        margin-top: 1px;
        position: relative;
        top: 0;
        right: 0px;
        padding: 3px;
        padding-left: 13px;
        border-radius: 20px 20px;
        background-color: #f9f9f9;
        font-size: medium;
        display: flex;
        height: 26px;
        .icon {
          position: absolute;
          font-size: 14px;
          top: 6px;
          left: 14px;
          color: #958d8d;
          &:active,
          &:focus {
            color: #4d4bac;
          }
        }
        .search-input {
          background-color: #f9f9f9;
          height: 18px;
          width: 160px;
          padding: 3px 3px 3px 30px;
          border-radius: 3px;
          box-sizing: border-box;
          box-shadow: none;
          border: none;
          opacity: 0.5;
          outline: none;
          color: black;
          font-size: 13px;

          &:focus,
          &:active {
            opacity: 1;
          }
          &::placeholder {
            font-size: 10.5px;
            color: #958d8d;
          }
        }
      }
    }
    .ul-societe-style {
      height: calc(100vh - 260px);
      overflow-x: scroll;
      background-color: #ffffff;
      list-style-type: none;
      &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #ffffff;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #7978aa;
        border-radius: 7px;
      }
    }
    .ul-societe-style-display {
      overflow-x: scroll;
      background-color: #ffffff;
      list-style-type: none;
    }
    .li-societe-style {
      padding: 3px;
      float: left;
      margin: 2px;
      border-radius: 10px;
      display: inline;
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
      width: 95%;
      font-size: 10px;
      padding-bottom: 0px;
      padding-top: 0px;
      &:hover {
        background-color: #dfe0ff;
      }
    }
    .li-societe-style-active {
      background-color: #9799d6;
      color: white;
    }
    .check-icon-style {
      float: right;
      margin-top: 4px;
      color: #fff;
      margin-left: 4px;
      font-size: 12px;
    }
  }

  .block-rigth-content {
    width: 85%;
    background: #fff;
    border-radius: 13px;
    height: calc(100vh - 171px);
    padding: 9px 19px 0px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bcbcde94;
      border-radius: 7px;
    }
    .recap-item-block {
      width: 100% !important;
      box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%),
        0 2.6px 8px -1px rgb(0 0 0 / 6%), 0 5.7px 12px -1px rgb(0 0 0 / 5%),
        0 15px 15px -1px rgb(0 0 0 / 4%) !important;
    }
    .master-item-block {
      box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%),
        0 2.6px 8px -1px rgb(0 0 0 / 71%), 0 5.7px 12px -1px rgb(0 0 0 / 11%),
        0 15px 15px -1px rgb(0 0 0 / 2%);
      width: 98%;
      padding: 0;
      background-color: #fff;
      border-radius: 0;
      margin-bottom: 20px;
      .chart-title {
        display: flex;
        justify-content: space-between;
        // flex-direction: column;
        position: relative;
        padding: 12px 12px !important;
        border-radius: 0 !important;
        margin-bottom: 10px;
        margin-top: 0 !important;
        font-size: 16px;
        border-bottom: 4px solid #8d8cb7;
      }
      .recap-title {
        display: flex;
        justify-content: space-between;
        // flex-direction: column;
        position: relative;
        padding: 12px 12px !important;
        border-radius: 10px 10px 0px 0px !important;
        margin-bottom: 10px;
        margin-top: 0 !important;
        font-size: 16px;
        font-weight: 500;
        background: #8d8cb7;
        color: white;
      }
      .chart-content {
        padding: 4px;
        font-size: 10px;
        .circle-week-color {
          height: 8px;
          width: 8px;
          display: block;
          border-radius: 50%;
          background: #868484;
          margin-right: 1px;
        }
        .block-week {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
        .div-first-chart {
          overflow-x: auto;
          &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #ffffff;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: #bcbcde94;
            border-radius: 7px;
          }
        }

        .border-top-filiale {
          border-top: 2px solid black;
        }
      }
    }
    .table-conter-style {
      font-size: 10px;
      font-weight: bolder;
    }
  }
  .time-style-statistic-oblige {
    background: #e2e3f6;
    font-size: 5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    padding: 3px;
    color: #0d1186;
    &:hover {
      background: #0d1186;
      color: #e2e3f6;
    }
  }
  .cfs-10 {
    font-size: 10px;
  }
}
</style>
<style lang="scss">
.table-statistic-oblige {
  display: block;
  font-size: 10px;
  overflow-x: scroll;
  width: 99%;
  .table {
    display: table !important;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bcbcde94;
    border-radius: 7px;
  }
}
</style>
