var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistic-oblige"},[_c('div',{staticClass:"entete"},[_c('div',{staticClass:"box-all-filter"},[_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(0),_c('date-picker',{staticClass:"custom-date-picker-calender-filter custom-date-picker-filter-white-back",attrs:{"value-type":"format","type":"date","required":"","value":_vm.date_debut,"disabled-date":_vm.disabledStartDate,"input-class":"custom-date-picker-filter"},on:{"input":function($event){return _vm.handleDateTimePicker($event, 'date_debut')}}})],1),_c('div',{staticClass:"box-label-champ mr-2"},[_vm._m(1),_c('date-picker',{staticClass:"custom-date-picker-calender-filter custom-date-picker-filter-white-back",attrs:{"value-type":"format","type":"date","value":_vm.date_fin,"clear-button":true,"disabled-date":_vm.disabledEndDate,"input-class":"custom-date-picker-filter"},on:{"input":function($event){return _vm.handleDateTimePicker($event, 'date_fin')}}})],1),_c('div',{staticClass:"box-label-champ mr-2 mb-2 mt-2"},[_vm._m(2),_c('multiselect',{staticClass:"customSelectMultipleFilter",attrs:{"options":_vm.getListOrganismeObligee,"multiple":true,"label":"name","track-by":"id","showLabels":false,"placeholder":"sélectionner organisme","close-on-select":false,"clear-on-select":false,"preserve-search":true,"loading":_vm.getLoadingDataFilterObligeeThProjects},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length)?_c('span',{class:{
                'multiselect__single showBlock': !isOpen,
                'multiselect__single ': isOpen
              }},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}]),model:{value:(_vm.organisme),callback:function ($$v) {_vm.organisme=$$v},expression:"organisme"}})],1),(_vm.getLoaderStatisticOblige)?_c('div',{staticClass:"chargement chargement-loading-icon mt-1 ml-3"},[_vm._m(3)]):_vm._e(),_c('div',{staticClass:"box-end-position"},[_c('b-button',{staticClass:"button-export-style ml-2 mr-1 button-white-with-icon-color",attrs:{"size":"sm","title":_vm.OpenAllBlock ? 'Fermé tous les blocs' : 'Ouvrir tous les blocs'},on:{"click":_vm.handleClickOpneAllBlock}},[_c('img',{attrs:{"height":"16px","width":"15px","src":require("@/assets/open-close.jpeg")}})]),_c('export-rapport-oblige-statistic',{attrs:{"filter":_vm.computedFilter,"genereRapport":_vm.exportRapportFactureObligee}}),_c('b-popover',{attrs:{"triggers":"focus","target":"popover-filter-obligee","placement":"top","custom-class":"my-custom-class-popover-filter"}},[_c('div',{staticClass:"box-label-champ mr-2 mb-2 mt-2"},[_c('div',{staticClass:"label-box-style w-126-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Organsime")])]),_c('multiselect',{staticClass:"customSelectMultipleFilter",attrs:{"options":_vm.getListOrganismeObligee,"multiple":true,"label":"name","track-by":"id","showLabels":false,"placeholder":"sélectionner organisme","close-on-select":false,"clear-on-select":false,"preserve-search":true,"loading":_vm.getLoadingDataFilterObligeeThProjects},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length)?_c('span',{class:{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}]),model:{value:(_vm.organisme),callback:function ($$v) {_vm.organisme=$$v},expression:"organisme"}})],1),_c('div',{staticClass:"box-label-champ mr-2 mb-2 mt-2"},[_c('div',{staticClass:"label-box-style w-126-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Filiale Installateur")])]),_c('multiselect',{staticClass:"customSelectMultipleFilter",attrs:{"options":_vm.getListInstallateurbligee,"multiple":true,"label":"name","track-by":"id","showLabels":false,"placeholder":"sélectionner filiale","loading":_vm.getLoadingDataFilterObligeeThProjects,"close-on-select":false,"clear-on-select":false,"preserve-search":true},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length)?_c('span',{class:{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}]),model:{value:(_vm.filiale),callback:function ($$v) {_vm.filiale=$$v},expression:"filiale"}})],1),_c('div',{staticClass:"box-label-champ mr-2 mt-2"},[_c('div',{staticClass:"label-box-style w-126-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Lot")])]),_c('multiselect',{staticClass:"customSelectMultipleFilter",attrs:{"options":_vm.getListLotsOligee,"multiple":true,"showLabels":false,"placeholder":"sélectionner lot","close-on-select":false,"clear-on-select":false,"preserve-search":true,"loading":_vm.getLoadingDataFilterObligeeThProjects},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length)?_c('span',{class:{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }},[_vm._v(_vm._s(values.length)+" options selected")]):_vm._e()]}}]),model:{value:(_vm.lot),callback:function ($$v) {_vm.lot=$$v},expression:"lot"}})],1),_c('div',{staticClass:"box-label-champ mr-2 mt-2"},[_c('div',{staticClass:"label-box-style w-126-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Statut")])]),_c('multiselect',{staticClass:"customSelectMultipleFilter",attrs:{"options":_vm.optionsSelectStatut,"multiple":true,"showLabels":false,"placeholder":"sélectionner statut","close-on-select":false,"clear-on-select":false,"preserve-search":true,"label":"full_name","track-by":"value"},on:{"input":_vm.handleFilter},scopedSlots:_vm._u([{key:"selection",fn:function({ values, isOpen }){return [(values.length)?_c('span',{class:{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }},[_vm._v(_vm._s(values.length)+" statut")]):_vm._e()]}}]),model:{value:(_vm.statue),callback:function ($$v) {_vm.statue=$$v},expression:"statue"}})],1)])],1)])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"block-left-filter",class:{
        'block-left-filter-disabled': !_vm.showBlockFilter
      }},[_c('div',{staticClass:"block-title"},[(_vm.showBlockFilter)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"title"},[_vm._v(" Liste master filiale ")]),(_vm.computedMasterList.length)?_c('font-awesome-icon',{staticClass:"time-style-statistic-oblige ml-1 mt-1",attrs:{"icon":_vm.master_id.length ? 'times' : 'plus',"title":_vm.master_id.length ? 'Vider la liste' : 'Sélectionne tout'},on:{"click":function($event){_vm.master_id.length
                ? (_vm.master_id = [])
                : (_vm.master_id = [..._vm.computedMasterList]);
              _vm.handleFilter();}}}):_vm._e()],1):_vm._e(),_c('img',{staticClass:"flesh-icon",class:{ 'flesh-icon-rigth': _vm.showBlockFilter == false },attrs:{"height":"18px","width":"18px","src":require("@/assets/Icon ionic-md-arrow-dropright-circle.png")},on:{"click":function($event){_vm.showBlockFilter = !_vm.showBlockFilter}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBlockFilter),expression:"showBlockFilter"}],staticClass:"Search-style-component"},[_c('div',{staticClass:"search-box"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchMaster),expression:"searchMaster"}],staticClass:"search-input",attrs:{"placeholder":"Chercher"},domProps:{"value":(_vm.searchMaster)},on:{"input":function($event){if($event.target.composing)return;_vm.searchMaster=$event.target.value}}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBlockFilter),expression:"showBlockFilter"}],staticClass:"mt-2"},[(_vm.loderMaster)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Half-circle-spinner',{staticClass:"loeder",attrs:{"animation-duration":1000,"size":50,"color":'#4d4bac'}})],1):_c('ul',{staticClass:"ul-societe-style"},_vm._l((_vm.computedMasterList),function(item,index){return _c('li',{key:index,staticClass:"li-societe-style",class:{
              'li-societe-style-active': _vm.activeItem(item) === true
            },on:{"click":function($event){return _vm.clickItemList(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),(_vm.activeItem(item) === true)?_c('font-awesome-icon',{staticClass:"check-icon-style",attrs:{"icon":"check-circle"}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"block-rigth-content",style:(_vm.showBlockFilter ? '' : 'width:97%')},[_c('div',{staticClass:"master-item-block recap-item-block ml-2"},[_c('div',{staticClass:"recap-title"},[_c('span',{staticClass:"text"},[_vm._v(" REPARTITION DES FILIALES SUR LES MASTERS ")]),(!_vm.openRacapBlock)?_c('font-awesome-icon',{attrs:{"icon":"caret-down"},on:{"click":function($event){_vm.openRacapBlock = !_vm.openRacapBlock}}}):_c('font-awesome-icon',{attrs:{"icon":"caret-up"},on:{"click":function($event){_vm.openRacapBlock = !_vm.openRacapBlock}}})],1),(_vm.openRacapBlock)?_c('div',{staticClass:"chart-content"},[(_vm.getLoaderStatisticOblige)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Half-circle-spinner',{staticClass:"loeder",attrs:{"animation-duration":1000,"size":50,"color":'#4d4bac'}})],1):_vm._e(),(
              _vm.getDataStatisticOblige && _vm.getDataStatisticOblige.masters_logos
            )?_c('div',{staticClass:"d-flex"},_vm._l((_vm.getDataStatisticOblige.masters_logos),function(logo,key){return _c('div',{key:'logo' + key},[_c('img',{staticStyle:{"max-height":"20px","max-width":"100%","display":"inline-block","margin":"15px"},attrs:{"src":logo}})])}),0):_vm._e(),(
              _vm.getDataStatisticOblige &&
                _vm.getDataStatisticOblige.nombreFilialesMasters
            )?_c('mdbBarChart',{ref:"chartRef",staticClass:"chart",attrs:{"type":"bar","data":_vm.doughnutChartDataRcap,"options":_vm.doughnutChartOptionsRacap,"height":150}}):(!_vm.getLoaderStatisticOblige)?_c('div',{staticClass:"text-center"},[_vm._v(" Il n'y a aucun donné à afficher ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"master-item-block recap-item-block ml-2"},[_c('div',{staticClass:"recap-title"},[_c('span',{staticClass:"text"},[_vm._v(" DEPOTS DES MASTER FILIALES ")]),_c('div',{staticClass:"d-flex"},[(
                _vm.getDataStatisticOblige &&
                  _vm.getDataStatisticOblige.filiales_volumes
              )?_c('img',{staticClass:"mr-2 mt-2",attrs:{"height":"11px","width":"13px","src":require("@/assets/close-image-without-back.png"),"title":_vm.masterBlochVisibility
                  ? 'Fermé tous les blocs de master filiale'
                  : 'Ouvrir tous les blocs de master filiale'},on:{"click":_vm.hendleChangeVisibilityGlobalMasterBlok}}):_vm._e(),(!_vm.openMasterBlock)?_c('font-awesome-icon',{attrs:{"icon":"caret-down"},on:{"click":function($event){_vm.openMasterBlock = !_vm.openMasterBlock}}}):_c('font-awesome-icon',{attrs:{"icon":"caret-up"},on:{"click":function($event){_vm.openMasterBlock = !_vm.openMasterBlock}}})],1)]),(_vm.getLoaderStatisticOblige)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Half-circle-spinner',{staticClass:"loeder",attrs:{"animation-duration":1000,"size":50,"color":'#4d4bac'}})],1):_vm._e(),(
            _vm.getDataStatisticOblige &&
              _vm.getDataStatisticOblige.array_masters &&
              _vm.openMasterBlock
          )?_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{attrs:{"options":[
                  { text: 'Semaine', value: 'week' },
                  { text: 'Mois', value: 'month' }
                ],"aria-describedby":ariaDescribedby,"name":"plain-inline","plain":""},on:{"change":_vm.handleFilter},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}})]}}],null,false,3578492048)})],1),_c('div',{staticClass:"text-center mb-2"},[_vm._v(" RECAP DES MASTERS FILIALES ")]),_c('b-table-simple',{staticClass:"table-statistic-oblige",attrs:{"hover":"","small":"","caption-top":"","responsive":"","bordered":""}},[_c('b-thead',{attrs:{"head-variant":"light"}},[(_vm.groupBy == 'week')?_c('b-tr',[_c('b-th',{staticClass:"table-conter-style"},[_vm._v("Mois")]),_vm._l((_vm.computedTableHeader(
                    Object.values(_vm.getDataStatisticOblige.array_masters)[0]
                  )),function(f,indexE){return _c('b-th',{key:'th' + indexE,staticClass:"table-conter-style text-center",attrs:{"colspan":f.occ}},[_vm._v(_vm._s(f.item))])}),_c('b-th')],2):_vm._e(),_c('b-tr',[_c('b-th',{staticClass:"table-conter-style"},[_vm._v("Master filiale")]),_vm._l((Object.values(
                    _vm.getDataStatisticOblige.array_masters
                  )[0]),function(week_value,week_id){return _c('b-th',{key:'th-install' + week_id,staticClass:"table-conter-style"},[_vm._v(_vm._s(week_id))])}),_c('b-th',[_vm._v("Total")])],2)],1),_c('b-tbody',[_vm._l((_vm.computedTableData(
                  _vm.getDataStatisticOblige.array_masters
                )),function(filiale,key,indexI){return _c('b-tr',{key:'tr' + indexI},[_c('b-td',{attrs:{"variant":"active"}},[_vm._v(" "+_vm._s(key))]),_vm._l((filiale),function(week_value,week_id,indCol){return _c('b-td',{key:'td-install' + week_id,staticClass:"text-right",attrs:{"id":'td-install' + week_id}},[_vm._v(" "+_vm._s(filiale[ Object.keys( Object.values(_vm.getDataStatisticOblige.array_masters)[0] )[indCol] ])+" ")])}),_c('b-td',{staticClass:"text-right table-conter-style"},[_vm._v(_vm._s(_vm.computedTotalRow(filiale)))])],2)}),_c('b-tr',[_c('b-td',{staticClass:"table-conter-style",attrs:{"variant":"active"}},[_vm._v("Total")]),_vm._l((Object.values(
                    _vm.getDataStatisticOblige.array_masters
                  )[0]),function(week_value,week_id){return _c('b-td',{key:'td-total' + week_id,staticClass:"text-right table-conter-style"},[_vm._v(_vm._s(_vm.computedTotalCol( _vm.getDataStatisticOblige.array_masters, week_id )))])}),_c('b-td',{staticClass:"table-conter-style text-right"},[_vm._v(_vm._s(_vm.computedTotalGlobale(_vm.getDataStatisticOblige.array_masters)))])],2)],2)],1)],1):_vm._e(),(
            _vm.openMasterBlock &&
              _vm.getDataStatisticOblige &&
              _vm.getDataStatisticOblige.filiales_volumes
          )?_c('div',{staticClass:"chart-content"},_vm._l((_vm.getDataStatisticOblige.filiales_volumes),function(item,key,index){return _c('div',{key:index,staticClass:"master-item-block ml-2"},[_c('div',{staticClass:"chart-title",style:('border-color:' + _vm.getDataStatisticOblige.masters_colors[key])},[_c('img',{attrs:{"src":_vm.getDataStatisticOblige.masters_logos[key],"width":"100px","height":"25px"}}),_c('span',{staticClass:"text"},[_vm._v(" VOLUME EN GIGA POUR LE MASTER FILIALE "),_c('b',{staticClass:"ml-2"},[_c('span',{style:('color:' + _vm.getDataStatisticOblige.masters_colors[key])},[_vm._v(_vm._s(key))])])]),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.masterBlochVisibility[index] &&
                    _vm.masterBlochVisibility[index].value
                ),expression:"\n                  masterBlochVisibility[index] &&\n                    masterBlochVisibility[index].value\n                "}],attrs:{"icon":"caret-down"},on:{"click":function($event){return _vm.handleClickVisibilityMasterBlock(index)}}}),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.masterBlochVisibility[index] &&
                    _vm.masterBlochVisibility[index].value == false
                ),expression:"\n                  masterBlochVisibility[index] &&\n                    masterBlochVisibility[index].value == false\n                "}],attrs:{"icon":"caret-up"},on:{"click":function($event){return _vm.handleClickVisibilityMasterBlock(index)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.computedMasterBlochVisibility(index)),expression:"computedMasterBlochVisibility(index)"}],staticClass:"chart-content"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Volume en GIGA par FILIALE")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.groupBy == 'week' ? 'Semaine' : 'Mois')+" ")]),_c('div',{staticClass:"block-week"},_vm._l((_vm.computedWeekList(item)),function(weekElement,indexW){return _c('div',{key:'master' + index + 'week' + indexW,staticClass:"d-flex align-items-center mr-2"},[_c('span',{staticClass:"circle-week-color",style:(weekElement.color
                          ? 'background:' + weekElement.color
                          : '')}),_c('span',[_vm._v(_vm._s(weekElement.week))])])}),0)]),_c('div',{staticClass:"d-flex justify-content-start div-first-chart mb-4 mt-4"},[_vm._v(" "+_vm._s(_vm.doughnutChartData.backgroundColor)+" "),_vm._l((item),function(filiale,indexF){return _c('div',{key:'f' + indexF},[_c('mdbBarChart',{ref:"chartRef",refInFor:true,staticClass:"chart",attrs:{"type":"bar","data":_vm.doughnutChartData(filiale, key),"options":_vm.doughnutChartOptions(indexF),"height":170}})],1)})],2),_c('div',{staticClass:"border-top-filiale mb-4 mt-4 pt-4",style:('border-color:' + _vm.getDataStatisticOblige.masters_colors[key])},[_c('mdbBarChart',{ref:"chartRef",refInFor:true,staticClass:"chart",attrs:{"type":"bar","data":_vm.doughnutChartDataInsta(
                      _vm.getDataStatisticOblige.masters_filiales_volumes[key]
                    ),"options":_vm.doughnutChartOptionsInsta,"height":150}})],1),_c('div',[_c('b-table-simple',{staticClass:"table-statistic-oblige",attrs:{"hover":"","small":"","caption-top":"","responsive":"","bordered":""}},[_c('b-thead',{attrs:{"head-variant":"light"}},[(_vm.groupBy == 'week')?_c('b-tr',[_c('b-th',{staticClass:"table-conter-style"},[_vm._v("Mois")]),_vm._l((_vm.computedTableHeader(
                          Object.values(item)[0]
                        )),function(f,indexE){return _c('b-th',{key:'th' + indexE,staticClass:"table-conter-style text-center",attrs:{"colspan":f.occ}},[_vm._v(_vm._s(f.item))])}),_c('b-th')],2):_vm._e(),_c('b-tr',[_c('b-th',{staticClass:"table-conter-style"},[_vm._v("Installateur RGE")]),_vm._l((Object.values(
                          item
                        )[0]),function(week_value,week_id){return _c('b-th',{key:'th-install' + week_id,staticClass:"table-conter-style"},[_vm._v(_vm._s(week_id))])}),_c('b-th',[_vm._v("Total")])],2)],1),_c('b-tbody',[_vm._l((_vm.computedTableData(
                        item
                      )),function(filiale,key,indexI){return _c('b-tr',{key:'tr' + indexI},[_c('b-td',{attrs:{"variant":"active"}},[_vm._v(" "+_vm._s(key))]),_vm._l((filiale),function(week_value,week_id,indCol){return _c('b-td',{key:'td-install' + week_id,staticClass:"text-right",attrs:{"id":'td-install' + week_id}},[_vm._v(" "+_vm._s(filiale[Object.keys(Object.values(item)[0])[indCol]])+" ")])}),_c('b-td',{staticClass:"table-conter-style text-right"},[_vm._v(_vm._s(_vm.computedTotalRow(filiale)))])],2)}),_c('b-tr',[_c('b-td',{staticClass:"table-conter-style",attrs:{"variant":"active"}},[_vm._v("Total")]),_vm._l((Object.values(
                          item
                        )[0]),function(week_value,week_id){return _c('b-td',{key:'td-total' + week_id,staticClass:"text-right table-conter-style"},[_vm._v(_vm._s(_vm.computedTotalCol(item, week_id)))])}),_c('b-td',{staticClass:"table-conter-style text-right"},[_vm._v(_vm._s(_vm.computedTotalGlobale(item)))])],2)],2)],1)],1)])])}),0):(!_vm.getLoaderStatisticOblige && _vm.openMasterBlock)?_c('div',{staticClass:"text-center cfs-10"},[_vm._v(" Il n'y a aucun donné à afficher ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label-box-style w-93-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Date début")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label-box-style w-63-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Date fin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label-box-style w-63-px"},[_c('span',{staticClass:"title-tabel"},[_vm._v("Organsime")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }